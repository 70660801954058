


$(document).ready(function() {
    $('.nav-tabs').wrap('<div class="nav-tabs-wrap"></div>');

    if (window.innerWidth < 1200) {
        $('.menu-item-has-children').on('click', '> a', function(evt) {
            evt.preventDefault();
            $(this).next().slideToggle(300);
            $(this).toggleClass('mobile-active');
        });
        $('.open-menu').on('click', function() {
            $(this).toggleClass('toggle-active');
            $('.navigation .menu, .search-box').slideToggle(300);
        });
        $('html').on('click', function() {
            $('.open-menu').removeClass('toggle-active');
            $('.navigation .menu, .search-box').slideUp(300);
        });
        $('.navigation .menu, .open-menu').on('click', function(evt) {
            evt.stopPropagation();
        });
    }
});


function appConfig(uiSelectConfig, $stateProvider, $urlRouterProvider, uiGmapGoogleMapApiProvider){
    uiSelectConfig.theme = 'bootstrap';
    $stateProvider
        .state('inicio', {
            url: '/',
            templateUrl: baseREST + 'paginas/inicio',
            controller: inicioCtrl,
            controllerAs: 'inicio'
        })
        .state('catalogo', {
            url: '/catalogo',
            templateUrl:  baseREST + 'paginas/catalogo',
            controller: catalogoCtrl,
            controllerAs: 'catalogo'
        })
        .state('favoritos', {
            url: '/favoritos',
            templateUrl:  baseREST + 'paginas/favoritos',
            controller: catalogoCtrl,
            controllerAs: 'catalogo'
        })
        .state('ficha', {
            url: '/catalogo/ficha/:id',
            templateUrl: baseREST + 'paginas/ficha',
            controller: fichaCtrl,
            controllerAs: 'ficha'
        })
        .state('empresa', {
            url: '/empresa',
            templateUrl:  baseREST + 'paginas/empresa',
            controller: estaticasCtrl,
            controllerAs: 'estatica'
        })
        .state('pistacho',{
            url: '/pistacho',
            templateUrl: baseREST + 'paginas/pistacho',
            controller: estaticasCtrl,
            controllerAs: 'estatica'
        })
        .state('almendro',{
            url: '/almendro',
            templateUrl: baseREST + 'paginas/almendro',
            controller: estaticasCtrl,
            controllerAs: 'estatica'
        })
        .state('contacto',{
            url: '/contacto',
            templateUrl: baseREST + 'paginas/contacto',
            controller: contactoCtrl,
            controllerAs: 'contacto'
        })
    $urlRouterProvider.otherwise("/");
    uiGmapGoogleMapApiProvider.configure({
        key: 'AIzaSyCQublkSRP20JXTUsRH9IngXEtfb-2SB2U',
        v: '3.20', //defaults to latest 3.X anyhow
        libraries: 'weather,geometry,visualization'
    });
}

function TodoEspecies($resource) {
    var url = baseREST + "todoespecies/:id";
    return $resource(url, {
        id: "@_id"
    }, {});
}

function Especies($resource) {
    var url = baseREST + "especies/:id";
    return $resource(url, {
        id: "@_id"
    }, {});
}

function Generos($resource) {
    var url = baseREST + "generos/:id";
    return $resource(url, {
        id: "@_id"
    }, {});
}

function Biotipos($resource) {
    var url = baseREST + "biotipos/:id";
    return $resource(url, {
        id: "@_id"
    }, {});
}
function Familias($resource) {
    var url = baseREST + "familias/:id";
    return $resource(url, {
        id: "@_id"
    }, {});
}

function Colores($resource) {
    var url = baseREST + "colores/:id";
    return $resource(url, {
        id: "@_id"
    }, {});
}

function Filtros($resource) {
    var url = baseREST + "filtros/:id";
    return $resource(url, {
        id: "@_id"
    }, {});
}

function Zonas($resource) {
    var url = baseREST + "zonas/:id";
    return $resource(url, {
        id: "@_id"
    }, {});
}

function Epocas($resource) {
    var url = baseREST + "epocas/:id";
    return $resource(url, {
        id: "@_id"
    }, {});
}

function ResultadosBusqueda($log){
    var interfaz = {
        especiesFiltradas: [],
        mostrarBuscador: false,
        especiesFavoritas: [],
        mostrarFiltrosBuscador: false,
    }
    return interfaz;
};

angular
    .module('zuaimeApp', [
        'ngYoutubeEmbed',
        'ngAnimate',
        'ngCookies',
        'ngResource',
        'ui.router',
        'ngSanitize',
        'ngAria',
        'ui.select',
        'uiGmapgoogle-maps',
        'ng-slide-down'
    ])
    .config(appConfig)
    .controller('appCtrl', appCtrl)
    .controller('inicioCtrl', inicioCtrl)
    .controller('catalogoCtrl', catalogoCtrl)
    .controller('fichaCtrl', fichaCtrl)
    .controller('buscadorCtrl', buscadorCtrl)
    .controller('estaticas', estaticasCtrl)
    .controller('contactoCtrl', contactoCtrl)
    .factory('ResultadosBusqueda', ResultadosBusqueda)
    .factory('Especies', Especies)
    .factory('Generos', Generos)
    .factory('Familias', Familias)
    .factory('Biotipos', Biotipos)
    .factory('Colores', Colores)
    .factory('Filtros', Filtros)
    .factory('Zonas', Zonas)
    .factory('Epocas', Epocas)
    .factory('TodoEspecies', TodoEspecies);
